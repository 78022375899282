<template>
<div>
    <div class="flex items-center justify-between flex-wrap">
        <div class="w-screen md:w-full md:px-6 md:py-4 bg-white ">
            <div class="md:relative">
                <Header />
            </div>
            <div class="relative  bg-white shadow-md">
                <div class="flex min-h-80">
                    <!-- <div class="md:w-1/4 w-0">
                        <Sidebar />
                    </div> -->
                    <div class="w-full px-6 py-6 shadow-lg">
                     <router-view v-slot="{ Component }">
                                <component :is="Component" />
                            </router-view>
                    </div>
                </div>
                <div class="relative">
                    <Service />
                </div>
            </div>
        </div>
    </div>
<Logo />
<Footer />
<Chat />
</div>
</template>
<script>
import Header from '@/components/website/Header'
import Sidebar from '@/components/website/Sidebar'
import Produk from '@/components/website/Produk'
import Service from '@/components/website/Service'
import Logo from '@/components/website/Logo'
import Footer from '@/components/website/Footer'
import Chat from '@/components/website/Chat'
export default {
        components : {
            Header,
            Sidebar,
            Produk,
            Service,
            Logo,
            Footer,
            Chat,
        },
    }
</script>
