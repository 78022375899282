<template>
    <nav class="bg-purple-600 shadow-md md:rounded-t-xl ">
        <div class="container mx-auto px-3 py-3 md:flex md:justify-between md:items-center">
            <div class="flex justify-between items-center">
                <div>
                    <a class="text-gray-800 text-xl font-bold md:text-2xl hover:text-gray-700" href="#">
                        <img src="@/assets/images/logo/teknologi.png" alt="" class="h-20 xl:h-20 justify-center rounded-lg mr-2">
                    </a>
                </div>
                <!-- Mobile menu button -->
                <div class="flex md:hidden">
                    <button type="button" @click="menu = !menu" class="text-white hover:text-yellow-500 focus:outline-none focus:text-white" aria-label="toggle menu">
                        <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
                            <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
            <div class="md:flex hidden px-3 items-center">
                <div class="flex flex-col md:flex-row md:mx-6">
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Beranda' }" >Beranda</router-link></a>
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Tentang' }" >Tentang Kami</router-link></a>
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Produk' }" >Produk</router-link></a>
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Sewa' }" >Cara Bayar</router-link></a>
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Faq' }" >FAQ</router-link></a>
                </div>
                <div class="relative text-gray-600">
                    <input type="search" name="serch" placeholder="Search" class="bg-white h-10 px-1 pr-10 rounded-full text-xs focus:outline-none">
                    <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
                        <svg class="h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div v-show="menu" class="md:hidden px-2 items-center">
                <div class="flex flex-col items-center mt-6 justify-between md:flex-row md:mx-6">
                    <a class="my-1 text-sm text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Beranda' }" >Beranda</router-link></a>
                    <a class="my-1 text-sm text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Tentang' }" >Tentang Kami</router-link></a>
                    <a class="my-1 text-xs text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Produk' }" >Produk</router-link></a>
                    <a class="my-1 text-sm text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Sewa' }" >Cara Bayar</router-link></a>
                    <a class="my-1 text-sm text-white font-bold  hover:text-yellow-500 md:mx-4 md:my-0" href="#"><router-link :to="{ name: 'Faq' }" >FAQ</router-link></a>
                </div>
                <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                     >
                <div class="mt-4 pl-10 flex flex-col text-gray-600">
                    <input type="search" name="search" placeholder="Search" class="items-center w-3/4 bg-white h-11 px-1 rounded-full text-sm focus:outline-none">
                    <button type="submit" class="absolute text-white right-14 mt-3">
                        <svg class="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
                        </svg>
                    </button>
                </div>
                </transition>
            </div>
        </div>
    </nav>
</template>
<script>
  export default {
    el: '#post',
    name : 'Header Website',
    data(){
    return{
        menu: false,
    }
  },
    components:{

  },
    mounted() {

  },
    computed: {

  },
    methods:{
}
}
</script>
