<template>
    <!-- BUY ME A BEER AND HELP SUPPORT OPEN-SOURCE RESOURCES -->
<div class="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
    <div>
         
        <a title="Konsultasi" href="whatsapp://send?text=Hai,%20mau%20konsultasi%20?&phone=+6282245012207" target="_blank" class="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
          <img class="object-cover object-center w-full h-full rounded-full" src="@/assets/images/logo/wa.png"/>
        </a>
    </div>
</div>
</template>
