<template>
    <!-- component -->
<div class="py-12 bg-purple-300 md:rounded-b-lg">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
            <h3 class="mt-1 text-2xl text-center  font-medium tracking-tight text-white sm:text-3xl">Kenapa harus pesan di <span class="text-black ">Teknologi 89</span> ??</h3>
        </div>
        <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-8">
                <div class="relative">
                    <dt class="bg-purple-500 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-2">
                        <h4 class="absolute flex items-center justify-center left-8 md:top-6 top-12 md:w-20 rounded-md  font-regular text-sm text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                        </h4>
                        <div class="pt-1 px-2 py-4 ml-24 text-lg text-center leading-6 font-medium">
                            <h5 class="text-black">GRATIS KONSULTASI</h5>
                            <p class="text-white">Kami memberikan konsultasi gratis kepada Client dalam pengembangan</p>
                        </div>
                    </dt>
                </div>
                <div class="relative">
                    <dt class="bg-purple-500 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-2">
                        <h4 class="absolute flex items-center justify-center left-8 md:top-6 top-12 md:w-20 rounded-md  tracking-wide font-regular text-sm text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>                        
                        </h4>
                        <div class="pt-1 px-2 py-4 ml-24 text-lg text-center leading-6 font-medium">
                            <h5 class="text-black">GARANSI 100 %</h5>
                            <p class="text-white">Kami memberikan garansi kepada Client dalam perbaikan secara minor aplikasi</p>
                        </div>
                    </dt>
                </div>
                <div class="relative">
                    <dt class="bg-purple-500 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-2">
                        <h4 class="absolute flex items-center justify-center left-8 md:top-6 top-12 md:w-20 rounded-md  tracking-wide font-regular text-sm text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>                        
                        </h4>
                        <div class="pt-1 px-2 py-4 ml-24 text-lg text-center leading-6 font-medium">
                            <h5 class="text-black">TERPERCAYA</h5>
                            <p class="text-white">Kami selalu menjaga kepercayaan kepada Client agar dapat bekerja sama dalam melakukan pengembangan</p>
                        </div>
                    </dt>
                </div>
                <div class="relative">
                    <dt class="bg-purple-500 rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-2">
                        <h4 class="absolute flex items-center justify-center left-8 md:top-6 top-12 md:w-20 rounded-md  tracking-wide font-regular text-sm text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                            </svg>                        
                        </h4>
                        <div class="pt-1 px-2 py-4 ml-24 text-lg text-center leading-6 font-medium">
                            <h5 class="text-black">INTEGRASI</h5>
                            <p class="text-white">Melakukan Integrasi dengan System luar dengan ketentuan pihak yang berlaku</p>
                        </div>
                    </dt>
                </div>
            </dl>
        </div>
    </div>
</div>
</template>
