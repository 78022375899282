//import vue router
import { createRouter, createWebHistory } from 'vue-router'
//define a routes
const routes = [
      {
        path: '/404',
        name: 'Not Found',
        component: () => import('@/views/404.vue')
      },
            {
              path: '/', redirect: { name: 'Beranda' }
            },
            {
                path: '/beranda',
                name: 'Beranda',
                component: () => import('@/views/website/Beranda.vue')
            },
            {
                path: '/tentang',
                name: 'Tentang',
                component: () => import('@/views/website/Tentang.vue')
              },
              {
                path: '/sewa',
                name: 'Sewa',
                component: () => import('@/views/website/Sewa.vue')
              },
              {
                path: '/syarat',
                name: 'Syarat',
                component: () => import('@/views/website/Syarat.vue')
              },
              {
                path: '/produk',
                name: 'Produk',
                component: () => import('@/views/website/Produk.vue')
              },
              {
                path: '/faq',
                name: 'Faq',
                component: () => import('@/views/website/Faq.vue')
              },
              {
                path: '/testimoni',
                name: 'Testimoni',
                component: () => import('@/views/website/Testimoni.vue')
              },
              {
                path: '/produk/detail',
                name: 'Detail',
                component: () => import('@/views/website/Detail.vue')
              },
]
//create router
const router = createRouter({
    history: createWebHistory(),
    routes  // config routes
})
export default router
