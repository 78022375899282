<template>
   <div class="flex mt-10 justify-between items-center ">
<Carousel :settings="settings" :autoplay="6000" :wrap-around="true" :breakpoints="breakpoints">
    <Slide v-for="slide in 12" :key="slide">
      <div >
        <img src="https://images.unsplash.com/photo-1555725305-0406b7607be0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" alt="" class="px-3 py-5 mr-2 w-full rounded-2xl">
      </div>
    </Slide>
    <template #addons>
    <Pagination />
    </template>
  </Carousel>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel,Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'left',
    },
    image:[
    ],
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2,
        snapAlign: 'left',
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: 'start',
      },
    },
  }),
});
</script>
