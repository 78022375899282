<template>
    <div class="md:min-h-80 bg-gray-200 dark:bg-gray-900 py-4 px-4 flex flex-col justify-between flex-wrap sm:py-10">
    <Carousel :settings="settings" :autoplay="4000" :wrap-around="true" :breakpoints="breakpoints">
      <Slide v-for="slide in carouselSlides" :key="slide" >
        <div class="md:w-20" v-show="carouselSlides" >
          <img :src="require(`@/assets/images/partner/${slide}.png`)" alt=""  />
        </div>
      </Slide>
    <template #addons>
    </template>
  </Carousel>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'left',
    },
    carouselSlides:[
      "1","2","3","4","5","6","7","8"
    ],    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2,
        snapAlign: 'left',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
  }),
});
</script>
